<template>
  <div>
    <!--订单产品信息展示-->
    <div style="margin-top: 30px" class="order-title__bar op-16">
      <!-- 现货 -->
      <div class="erp-form__title f16">应收项目（审核已通过）</div>
      <div class="edit-btn__bar">
        <span class="edit-btn" @click="auditData(listData.detailList)" v-if="hasPermission('erp:fmArap:saveModifyAudit')">
          <img src="../../../../../assets/images/icon-edit.png" alt="" srcset="" style="margin-right: 3px; margin-top: -1px" />
          申请修改</span
        >
        <span class="edit-btn" @click="showDelete" v-if="baseData.arDeleteList && baseData.arDeleteList.length > 0">
          <img src="../../../../../assets/images/del-icon.png" alt="" style="margin-right: 3px; margin-top: -1px" />删除记录</span
        >
      </div>
    </div>
    <div class="claim_company">
      <el-table
        :data="listData.detailList"
        border
        class="egrid erp-table__list erp-view__table"
        :show-summary="listData.detailList.length >= 2"
        :summary-method="summaryFn"
      >
        <el-table-column label="费用类型" class-name="no-pd" align="center" show-overflow-tooltip width="120">
          <template #default="scope">
            {{ filterName(scope.row.feeTypeDesc) }}
          </template>
        </el-table-column>
        <el-table-column label="金额(人民币)" class-name="no-pd" align="center" show-overflow-tooltip width="150">
          <template #default="scope">
            {{ filterName(scope.row.amountRmbDesc) }}
          </template>
        </el-table-column>
        <el-table-column label="日期" class-name="no-pd" align="center" show-overflow-tooltip width="100">
          <template #default="scope">
            {{ filterName(scope.row.arPayDate) }}
          </template>
        </el-table-column>

        <el-table-column label="修改后金额" class-name="no-pd" align="center" show-overflow-tooltip width="150">
          <template #default="scope">
            {{ filterName(scope.row.amountRmbAfterModifyDesc) }}
          </template>
        </el-table-column>
        <el-table-column label="备注" class-name="no-pd" align="center" show-overflow-tooltip min-width="240">
          <template #default="scope">
            {{ filterName(scope.row.remark) }}
          </template>
        </el-table-column>
        <el-table-column label="凭据文件" class-name="no-pd" align="center" show-overflow-tooltip width="120">
          <template #default="scope">
            <tableFileContent
              :readOnly="true"
              :fileUrlsArray="scope.row.fileVoList"
              key-name="orderArapFileName"
              key-url="orderArapFileUrl"
              key-id="orderArapFileId"
            ></tableFileContent>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" class-name="no-pd" align="center" show-overflow-tooltip width="170">
          <template #default="scope">
            {{ filterName(scope.row.applyTime) }}
          </template>
        </el-table-column>
        <el-table-column label="创建人" class-name="no-pd" align="center" show-overflow-tooltip width="140">
          <template #default="scope">
            {{ filterName(scope.row.applyOperator) }}
          </template>
        </el-table-column>
        <el-table-column label="审核时间" class-name="no-pd" align="center" show-overflow-tooltip width="170">
          <template #default="scope">
            {{ filterName(scope.row.auditTime) }}
          </template>
        </el-table-column>
        <el-table-column label="审核人" class-name="no-pd" align="center" show-overflow-tooltip width="140">
          <template #default="scope">
            {{ filterName(scope.row.auditOperator) }}
          </template>
        </el-table-column>
        <el-table-column label="审核说明" class-name="no-pd" align="center" show-overflow-tooltip width="210">
          <template #default="scope">
            {{ filterName(scope.row.auditDesc) }}
          </template>
        </el-table-column>
        <el-table-column label="操作" class-name="no-pd" align="center" width="80" fixed="right">
          <template #default="scope">
            <div class="el-tooltip record__link" v-if="hasPermission('erp:fmArap:deleteAr') && scope.row.isAutogen === false" @click="deleteItem(scope.row)">
              删除
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <erp-drawer :visible="visible" @close="closeDialog" erp class="no-padding">
      <receivablesEditForm
        :data="rowData"
        :orderArapId="orderArapId"
        :version="version"
        :arTradeCurrencyDesc="arTradeCurrencyDesc"
        :baseData="baseData"
        type="edit"
        @cancel="closeDialog"
        v-if="showType === 1"
      ></receivablesEditForm>
      <arRecyle :data="baseData.arDeleteList" :arTradeCurrencyDesc="arTradeCurrencyDesc" v-if="showType === 2" @cancel="closeDialog"></arRecyle>
    </erp-drawer>
    <!-- 编辑弹层-->
  </div>
</template>
<script>
import receivablesEditForm from './receivablesEditForm';
import tableFileContent from '@/components/TableFileContent/TableFileContent.vue';
import arRecyle from './arRecyle';
export default {
  components: { receivablesEditForm, tableFileContent, arRecyle },
  props: {
    baseData: { type: [Object] },
    arTradeCurrencyDesc: { type: [String, Number] },
    orderArapId: { type: [String, Number] },
    version: { type: [String, Number] },
    data: {
      // 获取传递过来的详情
      type: [Object, Array],
      default: function () {
        return null;
      },
    },
  },
  data() {
    return {
      visible: false,
      rowData: null, // 当前行数据
      listData: null,
      showType: 1,
    };
  },
  created() {
    this.initData();
  },
  watch: {
    data: {
      handler() {
        this.initData();
      },
      deep: true,
    },
  },
  methods: {
    showDelete() {
      this.visible = true;
      this.showType = 2;
    },
    closeDialog(status) {
      this.visible = false;
      if (status) {
        this.$emit('cancel', true);
      }
    },
    auditData(row) {
      if (this.baseData.orderStatus === 1 || this.baseData.orderStatus === 4) {
        if (this.baseData.modifyAudit && this.baseData.modifyAudit.listVo.length > 0) {
          this.errorTip('当前订单已申请修改应收，请勿重复提交');
        } else {
          this.rowData = row;
          this.visible = true;
          this.showType = 1;
        }
      } else {
        this.errorTip('订单状态不是进行中或已取消，无法修改');
      }
    },
    deleteItem(row) {
      this.$erpConfirm('确定删除吗？')
        .then(() => {
          if (this.baseData.orderStatus === 1 || this.baseData.orderStatus === 4) {
            this.ajax({
              type: 'POST',
              url: '/malicrm/orderArap/deleteAr',
              data: {
                orderArapConfirmId: row.orderArapConfirmId,
                orderId: row.orderId,
              },
              success: (res) => {
                if (res.code === 200) {
                  this.$emit('cancel', true);
                } else {
                  this.errorTip(res.message);
                }
              },
            });
          } else {
            this.errorTip('订单状态不是进行中或已取消，无法删除');
          }
        })
        .catch(() => {
          return false;
        });
    },
    initData() {
      if (this.data) {
        this.listData = JSON.parse(JSON.stringify(this.data));
      } else {
        this.listData = {
          detailList: [],
        };
      }
    },

    summaryFn() {
      const summaryColumns = ['合计', '-', '', '', '', '', '', '', '', '', '', '', ''];
      const data = this.listData.detailList;
      let totalRmb = data.reduce((total, item) => {
        const price = Number(item.amountRmb);
        return total + price;
      }, 0);
      totalRmb = this._formatFloat(totalRmb, 2);
      summaryColumns[1] = this._thousandBitSeparator(totalRmb);
      const isNullArray = data.filter((v) => v.amountRmbAfterModify || v.amountRmbAfterModify === 0); // 判断是不是全空
      if (isNullArray.length > 0) {
        // 如果有一条不为空的那么取金额
        let totalmodifyRmb = data.reduce((total, item) => {
          const price = item.amountRmbAfterModify || item.amountRmbAfterModify === 0 ? Number(item.amountRmbAfterModify) : Number(item.amountRmb);
          return total + price;
        }, 0);
        totalmodifyRmb = this._formatFloat(totalmodifyRmb, 2);
        summaryColumns[3] = this._thousandBitSeparator(totalmodifyRmb);
      }
      return summaryColumns;
    },
  },
  filters: {
    filterName(val) {
      if (val === null || val === '') {
        return '-';
      } else {
        return val;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
