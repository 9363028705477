<template>
  <!-- 现货用录入应收 -->
  <div>
    <div class="erp-form__header op-16 header-bar__sticky">
      <div class="erp-form__title">录入应收项目</div>
      <div class="erp-form-bar">
        <el-button size="small" type="primary" class="erp-primary__btn" @click="submit">提交审核</el-button>
        <div class="erp-default__btn" @click="back">返回</div>
      </div>
    </div>

    <!-- 分割 -->
    <el-table :data="formData.dtoList" border class="egrid erp-table__list erp-view__table table-loading" id="scrollTables" v-loading="loading">
      <el-table-column label="费用类型" class-name="no-pd" align="center" width="210">
        <template v-slot:header>
          <span class="erp-req">费用类型</span>
        </template>
        <template #default="scope">
          <!-- <el-input :value="scope.row.feeTypeDesc" :readonly="true" placeholder @click.native="showFeeType(scope)"
            class="handler"></el-input> -->
          <el-select
            v-model="scope.row.feeType"
            clearable
            filterable
            @change="feeChange($event, scope.$index)"
            @focus="focusFeeOption(scope.$index)"
            @clear="clearFeeOption(scope.$index)"
            placeholder
          >
            <el-option :label="item.label" :value="item.value" v-for="item in Options.ar_fee_type_input_xhfw" :key="item.value"></el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="金额(人民币)" class-name="no-pd" align="center" width="120">
        <template v-slot:header>
          <span class="erp-req">金额(人民币)</span>
        </template>
        <template #default="scope">
          <el-input v-model="scope.row.amountRmb" placeholder :maxlength="11" @blur="changeAmount($event, scope.row, 'amountRmb')" clearable></el-input>
        </template>
      </el-table-column>
      <el-table-column label="日期" class-name="no-pd" align="center" width="160">
        <template v-slot:header>
          <span class>日期</span>
        </template>
        <template #default="scope">
          <el-date-picker
            v-model="scope.row.arPayDate"
            placeholder
            style="width: 100%"
            prefix-icon=" "
            @change="formatDate(scope.row, 'arPayDate', $event)"
          ></el-date-picker>
        </template>
      </el-table-column>
      <el-table-column label="凭据文件" class-name="no-pd" align="center" width="160">
        <template #default="scope">
          <div class="fileUrl-box">
            <tableFileContent
              :maxImg="10"
              :rowIndex="scope.$index"
              :fileUrlsArray="scope.row.attachmentDtoList"
              key-name="fmFileName"
              key-url="fmFileUrl"
              key-id="fmFileId"
              @getFileUrls="getFileUrls"
            ></tableFileContent>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="备注" class-name="no-pd" align="center">
        <template #default="scope">
          <el-input v-model="scope.row.remark" placeholder :maxlength="100"></el-input>
        </template>
      </el-table-column>
      <el-table-column width="48" fixed="right" class-name="no-pd" align="center">
        <template v-slot:header>
          <span class="el-icon-circle-plus" style="font-size: 18px" @click="addProduct" v-if="formData.dtoList.length < 20"></span>
        </template>
        <template #default="scope">
          <span class="list-del" @click="delItem(scope.row, scope.$index)">
            <img src="../../../../../assets/images/list-del.png" alt />
          </span>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="费用类型" :visible="feeTypeVisible" :append-to-body="true" @close="feeTypeVisible = false" width="749px" class="ar-free-dialog">
      <el-radio-group v-model="checkFeeType" class="erp-radio receivables-radio">
        <template v-for="item in Options.ar_fee_type_input_xhfw" :key="item.value">
          <el-radio :label="item.value">
            {{ item.label }}
          </el-radio>
        </template>
        <div class="border-dot" v-if="Options.ar_fee_type_input_xhfw.length > 6"></div>
      </el-radio-group>
      <div class="receivables-footer">
        <div class="erp-primary__btn" style="width: 80px; margin-right: 8px" @click="changeType">确定</div>
        <div class="erp-default__btn" @click="feeTypeVisible = false">取消</div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import tableFileContent from '@/components/TableFileContent/TableFileContent.vue';
import { store } from '@/store';
import { formatTime } from '@/utils/publicMethods';
export default {
  components: { tableFileContent },
  props: {
    type: { type: [String] },
    baseData: { type: [Object] }, // 基础信息
    arTradeCurrencyDesc: { type: [String, Number] },
    orderArapId: { type: [String, Number] },
    version: { type: [String, Number] },
    data: {
      type: [Object, Array],
    },
  },
  data() {
    return {
      activeItem: null,
      activeIndex: null,
      feeTypeVisible: false,
      checkFeeType: null,
      fileType: '.pdf, .doc, .docx, .jpg, .png, .gif, .txt, .xls, .xlsx',
      titleImage: '', // 图片标题
      fileNameArr: [], // 文件名称
      fileUrlArr: [], // 图片地址
      fileUrlsArray: [],
      Options: {
        ar_fee_type_input_xhfw: store.state.dict.options.ar_fee_type_input_xhfw,
      },
      arapAuditSeq: 0,
      formData: {
        dtoList: [
          {
            arapAuditSeq: 0,
            amountRmb: null,
            amountRmbAfterModify: null,
            apPickupAmount: null,
            arAmountTc: null,
            arExchangeRate: null,
            arPayDate: null,
            arSettleAmountTc: null,
            arTradeCurrency: null,
            orderArapId: this.orderArapId,
            orderId: this.baseData.orderId,
            attachmentDto: null,
            auditType: null,
            feeType: null,
            feeTypeDesc: null,
            orderArapAuditId: null,
            remark: null,
          },
        ],
        orderArapId: null,
        orderId: null,
        version: null,
      },
      loading: false,
      isAjaxLoading: false,
    };
  },
  created() {
    // 如果是新增应收时
    if (this.type === 'edit') {
      this.formData.dtoList = [];
      const tempData = JSON.parse(JSON.stringify(this.data));
      const data = Object.assign([], tempData);
      data.forEach((v, i) => {
        if (v.fileVoList && v.fileVoList.length > 0) {
          const attachmentDtoList = [];
          v.fileVoList.forEach((file) => {
            const objData = {
              fmFileId: file.orderArapFileId,
              fmFileName: file.orderArapFileName,
              fmFileUrl: file.orderArapFileUrl,
            };
            attachmentDtoList.push(objData);
          });
          data[i].attachmentDtoList = attachmentDtoList;
        } else {
          data[i].attachmentDtoList = null;
        }
      });
      if (data.length > 5) {
        this.loading = true;
        setTimeout(() => {
          this.formData.dtoList = data;
          this.loading = false;
        }, 100);
      } else {
        this.formData.dtoList = data;
        this.loading = false;
      }

      const seqIndex = this.data.map((v) => v.arapAuditSeq);
      this.arapAuditSeq = Math.max(...seqIndex) + 1;
    }
    this.initData();
  },
  methods: {
    formatDate(item, name, value) {
      item[name] = formatTime(value, 'YYYY-MM-DD');
    },
    showFeeType(scope) {
      this.checkFeeType = scope.row.feeType;
      this.activeIndex = scope.$index;
      this.activeItem = scope.row;
      this.feeTypeVisible = true;
      this.formData.dtoList[scope.$index].amountRmb = null;
    },
    initData() {
      const { orderArapId, orderId, version } = this.baseData;
      this.formData.orderArapId = orderArapId;
      this.formData.version = version;
      this.formData.orderId = orderId;
    },
    getFileUrls(params) {
      const { data, index } = params;
      const item = this.formData.dtoList[index];
      if (data.length > 0) {
        item.attachmentDtoList = data;
      } else {
        item.attachmentDtoList = null;
      }
    },
    changeExchangeRate(e, item, name) {
      let value = e.target.value;
      value = value.replace(/[^\d.]/g, ''); // 清除“数字”和“.”以外的字符
      value = value.replace(/\.{2,}/g, '.'); // 只保留第一个. 清除多余的
      value = value.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
      // 只能输入两个小数
      value = value.replace(/^(-)*(\d+)\.(\d\d\d\d).*$/, '$1$2.$3'); // eslint-disable-line
      if (value >= 100) {
        const index = value.indexOf('.');
        if (index > -1) {
          const intStr = value.substr(0, index - 1);
          const dotStr = value.substr(index);
          value = intStr + dotStr;
        } else {
          value = value.substr(0, 2);
        }
      }
      if (Number(value) === 0) {
        value = null;
      }
      item[name] = value ? Number(value) : null;
    },
    changeAmount(e, item, name) {
      let value = e.target.value;
      value = value.replace(/[^-\d.]/g, ''); // 清除“数字”和“.”以外的字符
      value = value.replace(/\.{2,}/g, '.'); // 只保留第一个. 清除多余的
      value = value.replace(/-{2,}/g, ''); // 只保留第一个. 清除多余的
      value = value.replace('-', '$#$').replace(/-/g, '').replace('$#$', '-');
      value = value.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
      // 只能输入两个小数
      value = value.replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); // eslint-disable-line
      if (value >= 10000000) {
        const index = value.indexOf('.');
        if (index > -1) {
          const intStr = value.substr(0, index - 1);
          const dotStr = value.substr(index);
          value = intStr + dotStr;
        } else {
          value = value.substr(0, 7);
        }
      }

      if (Number(value) === 0 && item.feeType !== 3 && item.feeType !== 1 && item.feeType !== 42) {
        value = null;
      }
      item[name] = value && !isNaN(Number(value)) ? Number(value) : null;
    },
    setScroll(status) {
      if (status) {
        this.$nextTick(() => {
          const el = document.querySelector('#scrollTables .el-table__body-wrapper');
          el.scrollLeft = 0;
        });
      }
    },
    changeType() {
      const item = this.activeItem;
      const value = this.checkFeeType;
      item.feeType = value;
      item.feeTypeDesc = this.Options.ar_fee_type_input_xhfw.filter((v) => v.value === value)[0].label;
      item.arExchangeRate = null;
      item.arPayDate = null;
      item.arSettleAmountTc = null;
      item.arAmountTc = null; // 结算金额
      const { finalAmount, expectAmount, tradeCurrency, supplierPaymentRatio } = this.baseData.orderAmountInfoVo;
      if (value === 1) {
        item.arSettleAmountTc = this._formatFloat(expectAmount, 2);
        item.arTradeCurrency = tradeCurrency;
        item.arPaymentRatio = supplierPaymentRatio;
      }
      if (value === 3) {
        item.arSettleAmountTc = this._formatFloat(finalAmount, 2);
        item.arTradeCurrency = tradeCurrency;
        item.arPaymentRatio = supplierPaymentRatio;
      }
      this.feeTypeVisible = false;
      this.formData.dtoList[this.activeIndex] = JSON.parse(JSON.stringify(item));
    },
    addProduct() {
      if (this.formData.dtoList.length >= 20) {
        return false;
      } else {
        this.arapAuditSeq += 1;
        const data = {
          arapAuditSeq: this.arapAuditSeq,
          amountRmb: null,
          orderArapId: this.orderArapId,
          orderId: this.baseData.orderId,
          amountRmbAfterModify: null,
          apPickupAmount: null,
          arAmountTc: null,
          arExchangeRate: null,
          arPayDate: null,
          arSettleAmountTc: null,
          arTradeCurrency: null,
          attachmentDto: null,
          auditType: null,
          feeType: null,
          orderArapAuditId: null,
          remark: null,
        };
        this.formData.dtoList.push(data);
      }
    },
    delItem(item, index) {
      if (
        !item.amountRmb &&
        !item.feeType &&
        !item.arPayDate &&
        !item.remark &&
        !item.attachmentDto &&
        !item.arAmountTc &&
        !item.arExchangeRate &&
        !item.arSettleAmountTc
      ) {
        this.delCofirm(item, index);
      } else {
        this.$erpConfirm('确定删除吗？')
          .then(() => {
            this.delCofirm(item, index);
          })
          .catch(() => {
            return false;
          });
      }
    },
    delCofirm(item, index) {
      const data = this.formData.dtoList;
      const SeqIndex = item.arapAuditSeq;
      data.forEach((v) => {
        if (v.arapAuditSeq >= SeqIndex) {
          v.arapAuditSeq -= 1;
        }
      });
      data.splice(index, 1);
    },
    /**
     * 提交事件
     */
    validateData() {
      const data = this.formData.dtoList;
      let status = true;
      if (data.length === 0) {
        this.errorTip('请录入数据');
        return false;
      }

      for (let i = 0; i < data.length; i++) {
        const item = data[i];
        if (!item.feeType) {
          this.errorTip('请填写所有带星号的必填项');
          status = false;
          break;
        }
        if (!item.amountRmb && item.amountRmb !== 0) {
          this.errorTip('请填写所有带星号的必填项');
          status = false;
          break;
        }
      }
      return status;
    },
    submit() {
      const status = this.validateData();
      if (status) {
        if (!this.isAjaxLoading) {
          this.isAjaxLoading = true;
          this.ajax({
            type: 'POST',
            url: '/malicrm/orderArap/saveArAudit',
            data: this.formData,
            success: (res) => {
              if (res.code === 200) {
                this.successTip('提交成功');
                setTimeout(() => {
                  this.isAjaxLoading = false;
                }, 1000);
                this.$emit('cancel', true);
              } else {
                this.isAjaxLoading = false;
                this.errorTip(res.message);
              }
            },
          });
        }
      }
    },
    back() {
      // 返回操作
      this.$emit('cancel', false);
    },
    /**
     * 选择费用类型change事件
     * @param val 选择的对象 {}
     * @param index 当前行索引
     */
    feeChange(val, index) {
      this.formData.dtoList[index].amountRmb = null;
      const item = this.activeItem ? this.activeItem : this.formData.dtoList[index];
      let value;
      if (val) {
        item.feeType = val;
        value = item.feeType;
        item.feeTypeDesc = this.Options.ar_fee_type_input_xhfw.filter((v) => v.value === value)[0].label;
      }
      item.arExchangeRate = null;
      item.arPayDate = null;
      item.arSettleAmountTc = null;
      item.arAmountTc = null; // 结算金额
      const { finalAmount, expectAmount, tradeCurrency, supplierPaymentRatio } = this.baseData.orderAmountInfoVo;
      if (value === 1) {
        item.arSettleAmountTc = this._formatFloat(expectAmount, 2);

        item.arTradeCurrency = tradeCurrency;
        item.arPaymentRatio = supplierPaymentRatio;
      }
      if (value === 3) {
        item.arSettleAmountTc = this._formatFloat(finalAmount, 2);
        item.arTradeCurrency = tradeCurrency;
        item.arPaymentRatio = supplierPaymentRatio;
      }
      this.formData.dtoList[this.activeIndex] = JSON.parse(JSON.stringify(item));
    },
    /**
     * 当前费用行获得焦点事件
     * @param index 当前行索引
     */
    focusFeeOption(index) {
      this.feeOptionIndex = index;
      this.activeIndex = index;
      this.activeItem = this.formData.dtoList[index];
    },
    /**
     * 删除费用事件
     * @param index 当前行索引
     */
    clearFeeOption(index) {
      this.formData.dtoList[index].feeType = null;
      this.formData.dtoList[index].feeTypeDesc = null;
    },
  },
  filters: {
    filterName(val) {
      if (val === null || val === '') {
        return '-';
      } else {
        return val;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.receivables-footer {
  border-top: 1px solid #dcdfe6;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.border-dot {
  border-bottom: 1px dashed #f0f2f5;
  position: absolute;
  top: 70px;
  left: 30px;
  right: 30px;
}
</style>
